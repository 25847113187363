import { PartnerInstance } from './partnerInstanceTypes';
import { Section } from './sectionTypes';

interface ContentfulImage {
  url: string;
}

export type UserFlags = {
  hasPendingJoinRequest: boolean;
  isAcceptedMember: boolean;
};

export type Image = {
  height: number;
  width: number;
};

export type Details = {
  image: Image;
  size: string;
};

export type CardImage = {
  contentType: string;
  details: Details;
  fileName: string;
  url: string;
};

type Attributes = {
  body: string;
  groupTimekitProjectId: string;
  linkedinUrl: string;
  media: any;
  name: string;
  placeholder: any;
  privateTimekitProjectId: string;
  slug: string;
  timekitResourceId: string;
};

type Data = {
  id: string;
  attributes: Attributes;
  links: {
    self: string;
  };
  type: string;
};

type TeacherAssistant = {
  data: Data;
  links: {
    self: string;
  };
};

export type Sys = {
  id: string;
  publishedAt: string;
};

type ProfilePicture = {
  url: string;
};

type Instructor = {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  profilePicture: ProfilePicture;
  slug: string;
  sys: Sys;
  title: string;
  prefix?: string;
};

export type University = {
  title: string;
  name: string;
  image: ContentfulImage;
  reverseLogo: ContentfulImage;
  horizontalLogo: ContentfulImage;
  croppedLogo?: string;
  logo?: {
    url?: string;
  };
};

type School = {
  name: string;
  logo: ContentfulImage;
  image: ContentfulImage;
  shortDescription: string;
  slug: string;
  darkBackgroundLogo: ContentfulImage;
  horizontalLogo: ContentfulImage;
  reverseLogo: ContentfulImage;
  university: University;
};

type SaveResourceInfo = {
  canUserUnsave: boolean;
  id: number;
  teamId: number;
  userId: number;
};

export type SurveyOption = {
  id: number;
  label: string;
  questionId: number;
  value: string | number | boolean;
};

export type SurveyQuestion = {
  id: number;
  text: string;
  type:
    | 'QuestionTypesEnum.SCALE'
    | 'QuestionTypesEnum.SELECT'
    | 'QuestionTypesEnum.BOOLEAN'
    | 'QuestionTypesEnum.FREE_TEXT';
  options?: SurveyOption[];
};

export type Survey = {
  contentfulProgramId: string;
  id: number;
  name: string;
  partnerId: string;
  percentageComplete: number;
  questions: SurveyQuestion[];
};

export type CourseDaySchedules = {
  day?: string;
  endTime?: string;
  startTime?: string;
};

export type CourseSchedule = {
  schedule?: CourseDaySchedules[];
  scheduleEndDate?: string;
  scheduleStartDate?: string;
};

// ESLint tripping here with the no-shadow rule even though there's no LMS var in the file
// eslint-disable-next-line no-shadow
export enum LMS {
  Moodle = 'moodle',
  Canvas = 'canvas',
  Brightspace = 'brightspace',
}

export enum SelfEnrolmentModes {
  SOLO = 'solo',
  JOIN = 'join',
}

export interface PaidCourseDepositData {
  id: string;
  isPaid: boolean;
  userId: number;
  invoiceId: string;
  courseSlug: string;
  contentfulId: string;
  depositAmount: number;
  discountCodes: string[];
  lmsInstanceId: string;
  stripePriceId: string;
  stripeProductId: string;
  createdAt: string;
  updatedAt: string;
}

export type Course = {
  acceptWaitlistEnrollmentOnly?: boolean;
  backgroundColor?: string;
  brightspaceOrgUnitId?: string;
  brightspaceUrl?: string;
  cardImage: CardImage;
  certificateUuid: string;
  completionRate?: number;
  courseAbout: string;
  courseModality?: string[];
  courseProgress: number;
  courseRevenue?: number;
  courseSchedule?: CourseSchedule;
  courseStatus: 'Registered' | 'Active' | 'Completed';
  courseUrl?: string;
  instructorsCollection: Instructor[];
  instructorImage: {
    url: string;
  };
  instructorName: string;
  isActive?: boolean;
  isAddedFromFedNet?: boolean;
  isAddedToFederatedNetwork?: boolean;
  isFedNetCourse?: boolean;
  isOwnCourseListing?: boolean;
  isWaitlistEnabled?: boolean;
  learnersCount?: number;
  lms: LMS;
  maxNumOfEnrollments?: number;
  membershipStatus: string;
  moodleCourseId?: string | number;
  minNumOfEnrollments?: number;
  mode: string[];
  parentCourse: {
    partner: PartnerInstance;
  };
  partner: PartnerInstance;
  partnerId: string;
  percentCourseComplete?: number | null;
  priceInCents?: number;
  school: School;
  sections: { attributes: Section; id: number }[];
  shortTitle: string;
  slug: string;
  startDate?: string;
  stripeProductId: string;
  surveys: Survey[];
  syllabus: string;
  sys: Sys;
  teacherAssistants?: TeacherAssistant[];
  teacherAssistantsCollection?: any[];
  terms?: string;
  userFlags?: UserFlags;
  depositEnabled?: boolean;
  depositAmount?: number;
  depositPercentage?: number;
  userPaymentData?: PaidCourseDepositData;
  id?: number;
  title?: string;
};

interface CertificateInterface {
  courseName: string;
  learnerName: string;
  completedAt: string;
  related: {
    courseSlug: string;
    imgCertificate: string;
    universityName: string;
    universityLogoUrl: string;
    pdfCertificate: string;
    cardImage: string;
  };
}

export type EnrolledSection = {
  acceptedMembersCount: number;
  brightspaceCourseSlug: string;
  brightspaceOrgUnitId: number;
  brightspaceSectionId: number;
  createdAt: string;
  description: string;
  endDate: string;
  image: string;
  name: string;
  public: boolean;
  startDate: string;
  teamType: string;
  updatedAt: string;
  userId: number;
  visible: boolean;
  moodleGroupId: number;
  moodleCourseId: number;
  canvasSectionId: number;
  canvasCourseId: number;
  course: Course;
  solo: boolean;
};

export type CourseState = {
  courses: {
    isLoading: boolean;
    data: Course[] | null;
    error: string;
  };
  enrollCourse: {
    isOpenModal: boolean;
  };
  enrolledSections: {
    isLoading: boolean;
    data: EnrolledSection | null;
    error: '';
  };
  myCourses: {
    isLoading: boolean;
    data: Course[] | null;
    error: string;
  };
  preview: {
    isLoading: boolean;
    data: Course | null;
    error: string;
  };
  savedCourses: {
    isLoading: boolean;
    data: Course[] | null;
    error: string;
  };
  unsaveCourse: {
    isLoading: boolean;
    success: boolean;
    error: '';
  };
  selectedCourse: {};
  certificate: {
    isLoading: boolean;
    data: CertificateInterface | Record<string, any> | null;
    error: string;
  };
};

export type CoursePreviewPayload = {
  courseSlug: string | number;
  userId: string | number;
  excludeAttributes?: string[];
};

export type GetCoursePayload = {
  payload: string;
};

export type GetSavedCourses = {
  content: {
    cardImage: CardImage;
    courseAbout: string;
    school: School;
    shortTitle: string;
    slug: string;
  };
  contentType: string;
  saves: SaveResourceInfo[];
  slug: string;
  id: string;
};
