// @ts-nocheck
import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { Redirect, Switch, withRouter } from 'react-router';
import Loadable from 'react-loadable';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';

import get from 'lodash.get';

import Loader from 'components/uielements/Loader';
import NooderaModal from 'components/NooderaModal';

import Dashboard from 'screens/Dashboard';
import Login from 'screens/Auth/Login';
import Signup from 'screens/Auth/Signup';

import globalSearchActions from 'ducks/globalSearch/actions';
import { partnerInstanceActions } from 'ducks/partnerInstance/actions';
import authActions from 'ducks/auth/actions';

import { ReactComponent as ExclamationIcon } from 'assets/svg/exclamation.svg';
import serverError from 'assets/images/server-error.json';

import { getPartnerCookie } from 'utils/cookies';
import { useIsUniversityInstance } from 'utils/customHooks';
import { isAuthenticated, useAuth } from 'utils/customHooks/auth';
import { saveUtmValues, UTM_VALUES } from 'utils/heap';
import { isNonNoodleInstance } from 'utils/privateLabel/index';
import { setFavicon } from 'utils/browser';
import { redirectWithUtmQueryStrings } from 'utils/urls';
import { getSessionStorageData } from 'utils/sessionStorage';
import { getIsPrivateLabel } from 'index';

import type { RoutesProps } from 'commons/types';

import ProtectedRoute from './ProtectedRoute';
import GuestRoute from './GuestRoute';
import type { GuestRouteProps, ProtectedRouteProps } from './Route.types';
import { useSetPrivateInstanceCookiesOnFocus } from 'utils/customHooks/privateLabel';

const NoodleToastContainer = React.lazy(
  () => import('components/uielements/NoodleToast/NoodleToastContainer'),
);
const PromptModal = React.lazy(() => import('components/PromptModal'));
const GoTop = React.lazy(() => import('components/GoTop'));

const GuestRouteHOC = (LoadableComponent) => (props: GuestRouteProps) =>
  (
    <GuestRoute {...props}>
      <LoadableComponent {...props} />
    </GuestRoute>
  );

const ProtectedRouteHOC = (LoadableComponent) => (props: ProtectedRouteProps) =>
  (
    <ProtectedRoute {...props}>
      <LoadableComponent {...props} />
    </ProtectedRoute>
  );

const trackingData = (pathname: string): void => {
  try {
    const landingPage = localStorage.getItem('landing_page');

    if (landingPage === null) {
      localStorage.setItem('landing_page', pathname);
    }

    if (pathname !== '/login' && pathname !== '/signup') {
      localStorage.setItem('last_page', pathname);
    }
  } catch (error) {
    console.error('Error: ', error);
  }
};

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

// TODO: Refactor this to use hooks completely instead of withRouter HOC?
const Routes = ({ isLoggedIn, pathname }: RoutesProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useAuth();
  const { data: globalPartner, isLoading } = useSelector(
    (state: any) => state.partnerInstance.globalPartner,
  );
  trackingData(pathname);
  saveUtmValues();
  useSetPrivateInstanceCookiesOnFocus(globalPartner);

  const partnerId = globalPartner?.partnerId;
  const shouldRedirectToVanityUrl: boolean =
    globalPartner.vanityUrlEnabled && !window.location.origin.includes('.noodle.com');

  const checkIfAccessTokenExpired = (): void => {
    const token = getPartnerCookie('access_token', partnerId);

    if (token && !isAuthenticated(partnerId)) {
      auth.logout({ partnerId });
    }

    // Check case in which the user deletes site data
    if (isLoggedIn && !token) {
      dispatch(authActions.loginReset());
      history.push('/login');
    }
  };

  // Redirect to login or signup without losing query params
  const redirectToFrontDomainUrlWithQueryParams = (
    authType: 'login' | 'signup' = 'login',
  ): void => {
    const destinationUrlSearchParams = window.location.search;
    const utmParams = getSessionStorageData(UTM_VALUES);
    const url = `${globalPartner.frontDomain}/${authType}${destinationUrlSearchParams}`;
    const preprendedSymbol = destinationUrlSearchParams ? '&' : '?';

    redirectWithUtmQueryStrings(url, utmParams, preprendedSymbol);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    checkIfAccessTokenExpired();
    const allowedRoutes = ['interest/', 'program/', 'search'];

    if (!allowedRoutes.some((route) => pathname.includes(route))) {
      dispatch(globalSearchActions.setCurrentTaxonomy(''));
    }
    if (pathname.includes('instance') && !isLoggedIn && !isAuthenticated(partnerId)) {
      history.replace(`/login?returnUrl=${encodeURIComponent(window.location.origin + pathname)}`);
    }
  }, [pathname, dispatch]);

  React.useEffect(() => {
    // is this required? AuthProvider is already fetching globalPartner
    if (!globalPartner && !isLoading) {
      dispatch(partnerInstanceActions.getGlobalPartnerRequest());
    }
  }, []);

  React.useEffect(() => {
    if (Object.keys(globalPartner)?.length && !isLoading) {
      setFavicon(globalPartner?.companyFavicon);
    }
  }, [globalPartner]);

  const FallbackComponent = () => (
    <NooderaModal.Small
      isOpen
      icon={<ExclamationIcon />}
      title='Something changed on the server'
      description='Yeah, that sounds tangled. But we’re already reloading the page to fix it.'
      btnText='Got It!'
      onClose={() => document.location.reload()}
      imageContent={serverError}
    />
  );

  return !globalPartner || isLoading ? (
    <Loader />
  ) : (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      <React.Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <NoodleToastContainer />
        </ErrorBoundary>
      </React.Suspense>
      <React.Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <PromptModal />
        </ErrorBoundary>
      </React.Suspense>
      <React.Suspense fallback={<Loader />}>
        <ErrorBoundary>
          <GoTop />
        </ErrorBoundary>
      </React.Suspense>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          {/* PUBLIC ROUTES START */}
          <SentryRoute
            exact
            path='/'
            component={(props) => {
              if (
                isNonNoodleInstance &&
                !isAuthenticated(partnerId) &&
                globalPartner?.isMicrositeEnabled &&
                !pathname.includes('/courses') &&
                !pathname.includes('/cart') &&
                !pathname.includes('/enroll') &&
                !pathname.includes('/waitlist')
              ) {
                window.location.replace(globalPartner?.micrositeLandingPageUrl);
                return null;
              }
              if (useIsUniversityInstance() && !isAuthenticated(partnerId)) {
                return <Redirect to='/login' />;
              }
              const Comp = isLoggedIn ? ProtectedRouteHOC(Dashboard) : GuestRouteHOC(Dashboard);
              return <Comp {...props} />;
            }}
          />
          <SentryRoute
            exact
            path='/login'
            component={() => {
              if (shouldRedirectToVanityUrl) {
                redirectToFrontDomainUrlWithQueryParams('login');
                return null;
              }

              return <Login />;
            }}
          />
          <SentryRoute
            exact
            path='/authorize'
            component={Loadable({
              loader: () => import('screens/Auth/Authorize'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/logout'
            component={Loadable({
              loader: () => import('screens/Auth/Logout'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/forbidden'
            component={Loadable({
              loader: () => import('screens/Auth/NoAccess'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/password-reset'
            component={Loadable({
              loader: () => import('screens/Auth/PasswordReset'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/invitation/:token'
            component={Loadable({
              loader: () => import('screens/Auth/Invitation'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/signup'
            component={() => {
              const hasExternalIdp = globalPartner?.hasExternalIdp;

              if (getIsPrivateLabel() || hasExternalIdp) {
                return <Redirect to='/login' />;
              }

              if (shouldRedirectToVanityUrl) {
                redirectToFrontDomainUrlWithQueryParams('signup');
                return null;
              }

              return <Signup />;
            }}
          />
          <SentryRoute
            path='/certificates/:uuid'
            component={Loadable({
              loader: () => import('screens/Certificate'),
              loading: Loader,
            })}
          />
          {/* PRIVATE LABEL INSTANCE AUTH GUARD */}
          {/* This is a fix for routing the '/courses' endpoint on the logged out GuestHomePrivateLabel page.
          /The '/courses' router is controlled by the Dashboard router, which is an internal component, and
          because the GuestHomePrivateLabel is rendered at this level, the '/courses' endpoint would be
          unreachable unless we allowed it to pass. We may want to think about a better method here in
          the future. */}
          (
          {isNonNoodleInstance &&
            !auth.isAuthenticated(partnerId) &&
            !pathname.includes('/course') &&
            !pathname.includes('/cart') &&
            !pathname.includes('/waitlist') && <Redirect to='/' />}
          )
          <SentryRoute
            exact
            path='/survey/:courseSlug/:id'
            component={Loadable({
              loader: () => import('screens/Survey'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/school/I:id-:name'
            render={(props) => {
              const {
                match: {
                  params: { id, name },
                },
              } = props;
              return <Redirect to={`/school/I${id}-${name}/details`} />;
            }}
          />
          <SentryRoute
            exact
            path='/school/I:id-:name/S:idSchool-:nameSchool'
            render={(props) => {
              const {
                match: {
                  params: { id, name, idSchool, nameSchool },
                },
              } = props;
              return <Redirect to={`/school/I${id}-${name}/S${idSchool}-${nameSchool}/details`} />;
            }}
          />
          {/* <SentryRoute
            exact
            path='/profile/:id'
            render={props => {
              const {
                match: {
                  params: { id }
                }
              } = props;
              return <Redirect to={`/profile/${id}/profile`} />;
            }}
            /> */}
          <SentryRoute
            exact
            path='/scheduler-widget'
            component={Loadable({
              loader: () => import('screens/SchedulerWidget'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/embed-widget/noodle-partners'
            component={Loadable({
              loader: () => import('screens/CalendarEmbed'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/schedule'
            component={Loadable({
              loader: () => import('screens/SchedulerMenu'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/schedule/:slug'
            component={Loadable({
              loader: () => import('screens/Scheduler'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/counselors/:slug'
            component={Loadable({
              loader: () => import('screens/Counselor'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/sandbox'
            component={Loadable({
              loader: () => import('screens/Sandbox'),
              loading: Loader,
            })}
          />
          <SentryRoute
            exact
            path='/onboarding'
            component={
              isNonNoodleInstance
                ? Loadable({
                    loader: () => import('screens/OnboardingPrivateLabel'),
                    loading: Loader,
                  })
                : Loadable({
                    loader: () => import('screens/Onboarding'),
                    loading: Loader,
                  })
            }
          />
          {/* PUBLIC ROUTES END */}
          {/* GUEST ROUTES START */}
          {/* <SentryRoute
            exact
            path='/myrecommendations/:educationTypeName/:programTypeName'
            component={GuestRouteHOC(
              Loadable({
                loader: () => import('screens/MyRecommendationsByProgram'),
                loading: Loader
              })
            )}
            /> */}
          <SentryRoute
            exact
            path='/partner-programs'
            component={GuestRouteHOC(
              Loadable({
                loader: () => import('screens/PartnerPrograms'),
                loading: Loader,
              }),
            )}
          />
          <SentryRoute
            exact
            path='/search'
            component={
              isNonNoodleInstance
                ? () => <Redirect to='/404' />
                : GuestRouteHOC(
                    Loadable({
                      loader: () => import('screens/GlobalSearch'),
                      loading: Loader,
                    }),
                  )
            }
          />
          <SentryRoute
            exact
            path='/search/:term'
            component={GuestRouteHOC(
              Loadable({
                loader: () => import('screens/GlobalSearch'),
                loading: Loader,
              }),
            )}
          />
          {/* GUEST ROUTES END */}
          {/* PRIVATE ROUTES START */}
          <SentryRoute
            exact
            path='/account-settings/:id/:tab/:searchId?'
            component={ProtectedRouteHOC(
              Loadable({
                loader: () => import('screens/AccountSettings/User/UserAccountSettingsContainer'),
                loading: Loader,
              }),
            )}
          />
          <SentryRoute
            exact
            path='/join-team/:teamId'
            component={ProtectedRouteHOC(
              Loadable({
                loader: () => import('screens/TeamInvite'),
                loading: Loader,
              }),
            )}
          />
          <SentryRoute
            path='/admin'
            component={ProtectedRouteHOC(
              Loadable({
                loader: () => import('screens/AdminDashboard'),
                loading: Loader,
              }),
            )}
          />
          <SentryRoute
            path='/'
            component={(props) => {
              const Comp = isLoggedIn ? ProtectedRouteHOC(Dashboard) : GuestRouteHOC(Dashboard);
              return <Comp {...props} />;
            }}
          />
          {/* PRIVATE ROUTES END */}
        </Switch>
      </React.Suspense>
    </Sentry.ErrorBoundary>
  );
};

const mapStateToProps = (state, props) => ({
  isLoggedIn: get(state, 'auth.isLoggedIn', false),
  pathname: get(props, 'location.pathname', ''),
});

export default withRouter(connect(mapStateToProps)(Routes));
