import * as React from 'react';
import SimpleCardWrapper from './SimpleCard.style';
import type { SimpleCardProps } from './SimpleCard.types';

const SimpleCard = ({
  children,
  backgroundColor = '#EBF6FA',
  height,
  className,
}: SimpleCardProps): React.ReactElement<any> | null => (
  <SimpleCardWrapper className={className} height={height} backgroundColor={backgroundColor}>
    {children}
  </SimpleCardWrapper>
);

export default SimpleCard;
