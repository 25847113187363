// @ts-nocheck
/* eslint-disable max-len */
import * as React from 'react';
import { ReactComponent as HomeIcon } from 'assets/svg/home.svg';
import { ReactComponent as HomeSolidIcon } from 'assets/svg/home_solid.svg';
import { ReactComponent as ProgramsIcon } from 'assets/svg/programs.svg';
import { ReactComponent as ProgramsSolidIcon } from 'assets/svg/programs_solid.svg';
import { ReactComponent as ResourcesIcon } from 'assets/svg/resources.svg';
import { ReactComponent as ResourcesSolidIcon } from 'assets/svg/resources_solid.svg';
import { ReactComponent as GroupsIcon } from 'assets/svg/groups.svg';
import { ReactComponent as GroupsSolidIcon } from 'assets/svg/groups_solid.svg';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { isNonNoodleInstance } from 'utils/privateLabel/index';

import type { EducationType } from './Navigation.types';

export const educationTypesPaths: EducationType[] = [
  {
    name: 'Graduate School',
    path: 'graduate-school',
  },
  {
    name: 'Undergrad',
    path: 'undergraduate-college',
  },
  {
    name: 'K-12',
    path: 'k-thru-12',
  },
  {
    name: 'Preschool',
    path: 'preschool',
  },
];

const getNavLinksPerRole = (
  role: string | null | undefined,
  isUniversityAdmin: boolean | false,
): any[] => {
  const nonPrivateLabelBaseLinks = [
    {
      name: 'Resources',
      icon: <ResourcesIcon />,
      solidIcon: <ResourcesSolidIcon />,
      path: '/resources/browse',
      isExternalUrl: true,
    },
    {
      name: 'separator',
      path: 'separator',
    },
    {
      name: 'Teams',
      icon: <GroupsIcon />,
      solidIcon: <GroupsSolidIcon />,
      path: '/teams',
    },
  ];

  const linkMetadata = {
    base: [
      {
        name: 'Home',
        icon: <HomeIcon />,
        solidIcon: <HomeSolidIcon />,
        path: '/',
      },
      {
        name: isNonNoodleInstance ? 'Courses' : 'Programs',
        icon: <ProgramsIcon />,
        solidIcon: <ProgramsSolidIcon />,
        path: '/courses/browse',
      },
    ],
    student: [],
    expert: [],
    admin: [
      {
        name: 'Home',
        icon: <HomeOutlinedIcon />,
        solidIcon: <HomeOutlinedIcon />,
        path: '/instance',
      },
      {
        name: 'Members',
        icon: <GroupOutlinedIcon />,
        solidIcon: <GroupOutlinedIcon />,
        path: '/instance/:partnerId/members',
      },
      {
        name: isNonNoodleInstance ? 'Courses' : 'Programs',
        icon: <SchoolOutlinedIcon />,
        solidIcon: <SchoolOutlinedIcon />,
        path: '/instance/:partnerId/programs',
      },
      {
        name: 'Finances',
        icon: <PaymentsIcon />,
        solidIcon: <PaymentsIcon />,
        path: '/instance/:partnerId/finances',
      },
      {
        name: 'Reports',
        icon: <AutoGraphIcon />,
        solidIcon: <AutoGraphIcon />,
        path: '/instance/:partnerId/reports',
      },
    ],
  };

  if (!isNonNoodleInstance) {
    linkMetadata.base = [...linkMetadata.base, ...nonPrivateLabelBaseLinks];
  }

  if (isUniversityAdmin) {
    const universityAdminLinks = [
      {
        name: 'Network',
        icon: <LanguageIcon />,
        solidIcon: <LanguageIcon />,
        path: '/instance/:partnerId/network',
      },
      {
        name: 'Instructors',
        icon: <AccountBoxIcon />,
        solidIcon: <AccountBoxIcon />,
        path: '/instance/:partnerId/instructors',
      },
      {
        name: 'Terms',
        icon: <AccountBoxIcon />,
        solidIcon: <AccountBoxIcon />,
        path: '/instance/:partnerId/terms',
      },
    ];
    linkMetadata.admin.splice(3, 0, ...universityAdminLinks);
  }

  const ascendingOrder = (
    a: {
      name: string;
      icon: string;
      path: string;
      order: number;
    },
    b: {
      name: string;
      icon: string;
      path: string;
      order: number;
    },
  ) => {
    if (a.order < b.order) {
      return -1;
    }

    if (b.order < a.order) {
      return 1;
    }

    return 0;
  };

  const sortLinks = (collection: any[], compare: (...args: Array<any>) => any): any[] =>
    collection.sort(compare);

  if (!role || Object.keys(linkMetadata).filter((r) => role === r).length !== 1) {
    return sortLinks([...linkMetadata.base, ...linkMetadata.student], ascendingOrder);
  }

  if (role) {
    return sortLinks([...linkMetadata[role]], ascendingOrder);
  }

  return sortLinks([...linkMetadata.base, ...linkMetadata[role]], ascendingOrder);
};

export const getCurrentTitleFromNavLinks = (pathName: string): Record<string, any> => {
  const links = getNavLinksPerRole();
  const currentLink: any | void = links.find((link) => {
    const linkPath = link.path.toLowerCase();
    return pathName === linkPath || (pathName.includes(linkPath) && linkPath !== '/');
  });
  return currentLink
    ? {
        type: 'string',
        value: currentLink.name,
      }
    : {};
};
export default getNavLinksPerRole;
