import * as React from 'react';
import moment from 'moment';
import NooderaButton from 'components/NooderaButton/index';
import { useSelector } from 'react-redux';
import {
  SectionCardButton,
  SectionCardContent,
  SectionCardTag,
  StyledSectionCard,
} from 'components/JoinTeamModal/SectionCard/SectionCard.styles';
import { Section } from 'commons/types/sectionTypes';
import { pluralize } from 'utils/string';

interface SectionCardProps {
  item: Section;
  onChange: (value: Section) => void;
}

const SectionCard = ({ item, onChange }: SectionCardProps): React.ReactElement<any> => {
  const { data: globalPartner } = useSelector((state) => state.partnerInstance?.globalPartner);
  return (
    <StyledSectionCard key={`section-card-${item?.id}`}>
      <SectionCardContent>
        <span>{item?.name}</span>
        <span>{`Currently ${item?.acceptedMembersCount} ${pluralize(
          'member',
          'members',
          item?.acceptedMembersCount,
        )}`}</span>
        <span>Starts {moment(item?.startDate).format('ll')}</span>
        <SectionCardTag
          img={globalPartner.companySmallLogo}
          alt='Company Card Tag'
          title={globalPartner.companyName}
          subtitle='Admin'
          size='48px'
        />
      </SectionCardContent>
      <SectionCardButton>
        <NooderaButton.CTA size='large' onClick={() => onChange(item)}>
          Select and Continue
        </NooderaButton.CTA>
      </SectionCardButton>
    </StyledSectionCard>
  );
};

export default SectionCard;
