import styled from 'styled-components';
import type { FunctionComponent } from 'react';
import SimpleCard from 'components/SimpleCard/index';
import UserTag from 'components/UserTag';

export const StyledSectionCard: FunctionComponent<any> = styled(SimpleCard)`
  display: flex;
  flex-direction: column;
  width: 99%;
  height: 264px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  background: transparent;

  & > * {
    display: flex;
    flex-grow: 1;
  }
`;
export const SectionCardTag: FunctionComponent<any> = styled(UserTag)`
  && {
    margin-top: ${({ theme }) => theme.noodera.spacing.x5};
    max-height: 48px;
    color: ${({ theme }) => theme.palette.greyscale.black};

    & > div:last-child {
      & > p {
        ${({ theme }) => theme.noodera.fontTypes.font4Medium};
      }

      & > div {
        ${({ theme }) => theme.noodera.fontTypes.font5};
      }
    }
  }
`;
export const SectionCardContent: FunctionComponent<any> = styled.div`
    && {
        flex-direction: column;
        background: transparent;
        gap: ${(props) => props.theme.noodera.spacing.x7};

        & > span:first-child {
        $${({ theme }) => theme.noodera.fontTypes.font4Medium};
        }

        & > span {
            ${({ theme }) => theme.noodera.fontTypes.font4};
            margin-bottom: $${({ theme }) => theme.noodera.spacing.x8};
        }
    }
`;
export const SectionCardButton: FunctionComponent<any> = styled.div`
  && {
    align-items: flex-end;
    max-height: 48px;

    & > .btn {
      ${({ theme }) => theme.noodera.fontTypes.font4Medium};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 0;

      & > svg {
        font-size: 16px;
        margin-right: ${({ theme }) => theme.noodera.spacing.x6};
      }
    }
  }
`;
