import { LMS } from './courseTypes';
import { PaginatedResponse } from './paginationTypes';
import type { PartnerInvite } from './partnerInvitesTypes';

// ESLint is going crazy here
// eslint-disable-next-line no-shadow
export enum PartnerInstanceMembershipStatuses {
  Active = 'active',
  Disabled = 'disabled',
}

// ESLint is going crazy here as well
// eslint-disable-next-line no-shadow
export enum PartnerInstanceUserPermissions {
  ParentInstanceAdmin = 'parent-instance-admin',
  InstanceAdmin = 'instance-admin',
  PartnerPermissionsManager = 'partner-permissions-manager',
  FlaskAdmin = 'flask-admin',
  Admin = 'admin',
  User = 'user',
}

// ESLint is going crazy again
// eslint-disable-next-line no-shadow
export enum PartnerInstanceMembershipRoles {
  Member = 'member',
  Admin = 'admin',
}

export type Membership = {
  id: string;
  avatar: string;
  lastLogin?: string;
  createdAt: string;
  email: string;
  completionRate?: number;
  enrolledCoursesCount: number;
  isInstanceAdmin: boolean;
  roleNames?: PartnerInstanceUserPermissions[];
  name: string;
  partnerId: string;
  status: PartnerInstanceMembershipStatuses;
  updatedAt: string;
  userId: number;
};
export type Learner = {
  avatar: string | null;
  email: string;
  firstName: string;
  isPremium: boolean;
  lastName: string;
  learnerStatus: 'Not Started' | string;
  courseProgress: 0 | number;
  roleNames: PartnerInstanceUserPermissions[];
  id: number;
  type: PartnerInstanceMembershipRoles;
};
export type PartnerInstance = {
  activeMembersCount?: number;
  admins: {
    avatar: string;
    firstName: string;
    id: number;
    lastName: string;
    roleNames: string[];
  }[];
  completionRate?: number;
  contentfulId: string;
  contactSupportLinkAdmin?: boolean;
  contactSupportLinkLearners?: boolean;
  cookiePolicyLink?: string;
  createdById: number;
  currency: string;
  description: string;
  effectiveDomain?: string;
  firstBlockParagraph?: string;
  firstBlockTitle?: string;
  fromEmail: string;
  frontDomain: string;
  generalTeamId: number;
  heroParagraph?: string;
  heroTitle?: string;
  id: number;
  image: string;
  isActive: boolean;
  isMicrositeEnabled?: boolean;
  isPublished: boolean;
  isShoppingCartEnabled?: boolean;
  isCertificateGenerationEnabled?: boolean;
  membersCount: number;
  micrositeCoursesUrl?: string;
  micrositeLandingPageUrl?: string;
  mode: string;
  name: string;
  neverLoggedInCount?: number;
  noodleRevenueMax?: number;
  noodleRevenuePercentage?: number;
  parent: {
    partnerId: string;
    name: string;
    seatPrice?: number;
    stripeAccountId?: string;
    createInvoicesOnNoodleAccount: boolean;
    currency: string;
  };
  parentPartnerId: string;
  partnerId: string;
  primaryColor?: string;
  privacyPolicyLink?: string;
  remainingSeats: number;
  seats: number;
  secondaryColor?: string;
  seatPrice?: number;
  seatRequestCount: number;
  stripeAccountId?: string;
  termsOfUseLink?: string;
  totalEnrollments?: number;
  vanityUrl?: string;
  vanityUrlEnabled?: boolean;
};

export type PartnerInstanceMembership = {
  email: string;
  first_name: string;
  last_name: string;
  role: PartnerInstanceMembershipRoles;
  partnerId: string;
};

export type PartnerInstanceMembershipPatch = {
  status: PartnerInstanceMembershipStatuses;
  role: PartnerInstanceMembershipRoles;
};

export type PartnerInstanceSection = {
  acceptedMembers: {
    data: {
      id: string;
      type: 'user';
    }[];
  };
  admin: {
    data: {
      id: string;
      type: 'user';
    }[];
  };
  acceptedMembersCount: number;
  brightspaceCourseSlug: string;
  brightspaceOrgUnitId: number;
  brightspaceSectionId: number;
  createdAt: string;
  description: string;
  endDate: string;
  image: string;
  isPremium: boolean;
  name: string;
  partnerId: string;
  personal: boolean;
  public: boolean;
  startDate: string;
  teamType: string;
  updatedAt: string;
  userId: number;
  visible: boolean;
  id: number;
  soloLearnerData?: {
    avatar: string;
    name: string;
    email: string;
    courseProgress: string | number;
  };
};
export type PaginatedSection = Omit<PartnerInstanceSection, 'acceptedMembers' | 'admin'>;
export type PartnerInstanceCourse = {
  backgroundColor: any;
  brightspaceOrgUnitId?: string;
  completionRate: number;
  courseRevenue: number;
  courseUrl?: string;
  cardImage: {
    url: string;
  };
  courseAbout: string;
  courseObjective: any;
  learnersCount: number;
  lms: LMS;
  mode: string[];
  priceInCents: number;
  school: {
    shortDescription: string;
    university: {
      name: string;
    };
  };
  shortTitle: string;
  slug: string;
  syllabus: string;
  sys: {
    id: string;
    publishedAt: string;
  };
  teacherAssistantsCollection: {
    items: Record<string, any>[];
  };
  instructorsCollection: {
    items: Record<string, any>[];
  };
  partnerId?: string;
  instanceCount?: number;
  totalInstances?: number;
  isAddedToFederatedNetwork?: boolean;
  isAddedFromFedNet: boolean;
  isFromFederatedNetwork?: boolean;
  maxNumOfEnrollments?: number;
  minNumOfEnrollments?: number;
};

export type GlobalPartner = {
  partnerId: string;
  parentPartnerId: string;
  parentPartnerStripeAccountId: string;
  createInvoicesOnNoodleAccount: boolean | null;
  companyName: string;
  parentCompanyName: string;
  mode: string;
  companySlogan: string;
  companyLogo: string;
  companyReverseLogo: string;
  companySmallLogo: string;
  tableauLink: string;
  primaryColor?: string;
  secondaryColor?: string;
  heroTitle?: string;
  heroParagraph?: string;
  firstBlockTitle?: string;
  firstBlockParagraph?: string;
  privacyPolicyLink?: string;
  termsOfUseLink?: string;
  cookiePolicyLink?: string;
  contactSupportLinkAdmin?: string;
  contactSupportLinkLearners?: string;
  noodleRevenuePercentage?: number;
  noodleRevenueMax?: number;
  auth0ClientId: string;
  hasOnboardingSurvey?: boolean;
  isMicrositeEnabled?: boolean;
  micrositeLandingPageUrl?: string;
  micrositeCoursesUrl?: string;
  vanityUrl?: string;
  vanityUrlEnabled?: boolean;
  effectiveDomain: string;
  frontDomain: string;
  isShoppingCartEnabled?: boolean;
  isCertificateGenerationEnabled?: boolean;
  currency?: string;
  isSocialToolEnabled?: boolean;
  auth0EnterpriseConnectionName?: string;
  auth0EnterpriseConnectionDisplayName?: string;
  hasExternalIdp?: boolean;
  id: number;
};

export type PartnerInstanceState = {
  partnerInvites: {
    data: PartnerInvite[] | null;
    isLoading: boolean;
    error: string | null;
  };
  paginatedSections: {
    data: PaginatedResponse<PaginatedSection> | null;
    error: string | null;
    isLoading: boolean;
  };
  createPartnerInstanceMembership: {
    data: any | null;
    isLoading: boolean;
    error: string | null;
  };
  patchPartnerInstanceMembership: {
    data: any | null;
    isLoading: boolean;
    error: string | null;
  };
  memberships: {
    data: Membership[] | null;
    isLoading: boolean;
    error: string | null;
  };
  paginatedMemberships: {
    data: PaginatedResponse<Membership>;
    isLoading: boolean;
    error: string | null;
  };
  sections: {
    data: PartnerInstanceSection[] | null;
    isLoading: boolean;
    error: string | null;
  };
  section: {
    data: PartnerInstanceSection | null;
    isLoading: boolean;
    error: string | null;
  };
  paginatedSectionLearners: {
    data: PaginatedResponse<Learner>;
    isLoading: boolean;
    error: string | null;
  };
  courses: {
    data: PartnerInstanceCourse[];
    isLoading: boolean;
    error: string | null;
  };
  paginatedCourses: {
    data: PaginatedResponse<PartnerInstanceCourse>;
    isLoading: boolean;
    error: string | null;
  };
  course: {
    data: PartnerInstanceCourse | null;
    isLoading: boolean;
    error: string | null;
  };
  updateCourse: {
    data: PartnerInstanceCourse | null;
    isLoading: boolean;
    error: string | null;
  };
  sendPartnerInvites: {
    data: Record<string, any> | null;
    isLoading: boolean;
    error: string | null;
  };
  uploadCSV: {
    data: Record<string, any> | null;
    isLoading: boolean;
    error: string | null;
  };
  partnerInstance: {
    data: PartnerInstance | null;
    isLoading: boolean;
    error: string | null;
  };
  partnerInstances: {
    data: PartnerInstance[];
    isLoading: boolean;
    error: string | null;
  };
  paginatedPartnerInstances: {
    data: PaginatedResponse<PartnerInstance>;
    isLoading: boolean;
    error: string | null;
  };
  createPartnerInstance: {
    success: boolean | null;
    isLoading: boolean;
    error: string | null;
  };
  updatePartnerInstance: {
    success: boolean | null;
    isLoading: boolean;
    error: string | null;
  };
  createOrUpdateSection: {
    success: boolean | null;
    isLoading: boolean;
    error: string | null;
  };
  reports: {
    engagement: {
      isLoading: boolean;
      data: Record<string, any>;
      error: null;
    };
    learnerStatus: {
      isLoading: boolean;
      data: Record<string, any>;
      error: null;
    };
    surveys: {
      isLoading: boolean;
      data: Record<string, any>;
      error: null;
    };
    completion: {
      partner: {
        isLoading: boolean;
        data: Record<string, any>;
        error: string;
      };
      program: {
        isLoading: boolean;
        data: Record<string, any>;
        error: string;
      };
    };
    certificate: {
      isLoading: boolean;
      data: [];
      error: string;
    };
  };
  globalPartner: {
    data: GlobalPartner | null;
    isLoading: boolean;
    error: string | null;
  };
  finances: {
    stripeAccount: {
      isLoading: boolean;
      data: Object;
      error: string;
    };
    setupStripeAccount: {
      isLoading: boolean;
      success: boolean;
      error: string;
    };
    stripeInvoices: {
      data: Object;
      isLoading: boolean;
      error: string;
    };
    stripeInvoice: {
      data: any;
      isLoading: boolean;
      error: string;
    };
    paginatedStripeInvoices: {
      data: PaginatedResponse<[Object]>;
      isLoading: boolean;
      error: string;
    };
    invoicesTableData: {
      data: Object;
      isLoading: boolean;
      error: string;
      invoices: Object[];
    };
    receiptsTableData: {
      data: Object;
      isLoading: boolean;
      error: string;
      receipts: Object[];
    };
    partnerRevenueData: {
      data: Object;
      isLoading: boolean;
      error: string;
    };
    createStripeInvoice: {
      isLoading: boolean;
      data: any;
      error: string;
    };
    createStripeCourseInvoice: {
      isLoading: boolean;
      data: Object;
      error: string;
    };
    requestSeats: {
      isLoading: boolean;
      data: Object;
      error: string;
    };
    seatRequests: {
      isLoading: boolean;
      data: Object[];
      error: string;
    };
    reviewSeatRequest: {
      isLoading: boolean;
      data: Object;
      error: string;
    };
  };
  addMembersToSection: {
    data: null;
    isLoading: false;
    error: null;
  };
  addSoloMembersToCourse: {
    data: null;
    isLoading: false;
    error: null;
  };
};
