import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseUrl, images } from 'settings/index';

import Auth from 'components/Auth';
import MetaTags from 'components/MetaTags/index';
import Separator from 'components/uielements/Separator';

import authActions from 'ducks/auth/actions';

import { getQueryStringParams } from 'utils/string';
import { useAuth } from 'utils/customHooks/auth';
import { robotsConstants } from 'commons/constants/general';
import { getIsPrivateLabel } from 'index';
import { isNonNoodleInstance } from 'utils/privateLabel';

import { AUTH_HELP_TEXT } from 'commons/constants/auth';
import { TitleContainer } from './Signup.styles';

const SignupMetaTags: React.FC = () => {
  const { data: globalPartner, isLoading } = useSelector(
    (state: any) => state.partnerInstance.globalPartner,
  );

  if (isLoading) {
    return null;
  }

  const companyName = globalPartner?.companyName;
  const currentPathname = `${baseUrl}/signup`;
  const title = `Register`;
  const description = `Register for the ${companyName} Learning Platform.`;

  return (
    <MetaTags
      title={title}
      image={images.defaults.homeBanner}
      description={description}
      ogTitle={title}
      twitterCard={companyName}
      ogUrl={currentPathname}
      robots={robotsConstants.INDEX_FOLLOW}
    />
  );
};

const Signup: React.FC = () => {
  const { configureAuth0Lock, lockEmailField, setAuthHelpTextHTML, logout } = useAuth();
  const emailQuery = getQueryStringParams('email');
  const returnUrl = getQueryStringParams('returnUrl');
  const isLoggedIn = useSelector((state: any) => state.auth?.isLoggedIn);
  const userId = useSelector((state: any) => state.auth.userId);
  const dispatch = useDispatch();
  let pathname = '/signup';

  React.useEffect(() => {
    if (!isLoggedIn && userId) {
      pathname = returnUrl ? decodeURIComponent(returnUrl) : '/';
      dispatch(
        authActions.loginRequest({
          userId,
          guestRoute: true,
          pathname,
          logout,
        }),
      );
    }

    const auth0Lock = configureAuth0Lock();

    auth0Lock.hide();
    auth0Lock.show({
      initialScreen: 'signUp',
      auth: {
        params: {
          state: JSON.stringify({
            authType: 'signup',
            returnUrl,
          }),
        },
      },
    });

    if (emailQuery) {
      lockEmailField();
    }

    setAuthHelpTextHTML(AUTH_HELP_TEXT.SIGNUP);

    auth0Lock.on('signup ready', () => {
      if (getIsPrivateLabel()) {
        // Removing tabs
        const tabsContainer: HTMLElement | null = document.querySelector(
          '.auth0-lock-tabs-container',
        );
        tabsContainer?.remove();
      }

      if (isNonNoodleInstance) {
        const formContainer: HTMLElement | null =
          document.querySelector('.auth0-lock-input-email')?.parentElement;
        formContainer.classList.add('auth0-lock-form-container');

        if (returnUrl?.includes('cartSessionId')) {
          setAuthHelpTextHTML(AUTH_HELP_TEXT.SIGNUP);
        }
      }
    });
  }, []);

  return (
    <Auth role='main' id='main-id' aria-label='Sign up page'>
      <SignupMetaTags />
      <Auth.Logo />
      <Separator />
      {getIsPrivateLabel() ? (
        <TitleContainer>
          <span>Sign Up</span>
        </TitleContainer>
      ) : null}
      <Auth.Body />
    </Auth>
  );
};

export default Signup;
