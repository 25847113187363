// @ts-nocheck
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NooderaButton from 'components/NooderaButton/index';
import { ReactComponent as HeroSvg } from 'assets/svg/noodle-hero.svg';
import { ReactComponent as HeroSmSvg } from 'assets/svg/noodle-hero-small.svg';
import { ReactComponent as StudentSvg } from 'assets/svg/noodle-student.svg';
import { ReactComponent as StudentSmSvg } from 'assets/svg/noodle-student-small.svg';
import { partnerInstanceActions } from 'ducks/partnerInstance/actions';
import { useWidth } from 'utils/customHooks';
import CourseCard from 'components/CourseCard';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';
import { guestHomeHeroBlock, guestHomeFirstBlock } from 'commons/constants/guestHomePrivateLabel';
import {
  GuestHomeContainer,
  BannerSubtitle,
  BannerImageContainer,
  Section,
  SectionsWrapper,
  SectionsContainer,
  DoItTogetherSectionText,
  StyledInternalLink,
  DoItTogetherSectionTitle,
} from '../GuestHome/GuestHome.styles';
import {
  GuestHomePrivateLabelHeroSection,
  GuestHomePrivateLabelWrapper,
  GuestHeroSectionContainer,
  GuestHomeHeroSvgWrapper,
  GuestHomeSvgWrapper,
  CourseSectionContainer,
  CourseCardContainer,
  FirstBlockSection,
  SectionTitle,
  SectionSubtitle,
  CourseSectionWrapper,
  LeftHeroContent,
  HeroSection,
  BannerTitle,
} from './GuestHomePrivateLabel.styles';
import { baseUrl, images } from 'settings';
import { robotsConstants } from 'commons/constants';
import { GlobalPartner } from 'commons/types';
import MetaTagsComponent from 'components/MetaTags';

const FooterPrivateLabel = React.lazy(() => import('components/FooterPrivateLabel'));

const GuestHomeMetaTags: React.FC<{ partner: GlobalPartner; isLoading: boolean }> = ({
  partner,
  isLoading,
}) => {
  if (isLoading) {
    return null;
  }

  const currentPathname = `${baseUrl}`;
  const { companyName, companySlogan } = partner;
  const title = `${companyName}: ${companySlogan}`;
  const description = `Discover schools, degree programs, courses, connect with education experts, and find helpful information on ${companyName} — the most efficient, transparent, and personalized way to search for your education.`;

  return (
    <MetaTagsComponent
      title={title}
      image={images.defaults.homeBanner}
      description={description}
      ogTitle={title}
      twitterCard={companyName}
      ogUrl={currentPathname}
      robots={robotsConstants.INDEX_FOLLOW}
    />
  );
};

const GuestHomePrivateLabel: React.FC = () => {
  const dispatch = useDispatch();
  const width = useWidth();
  const { data: globalPartner } = useSelector((state) => state.partnerInstance.globalPartner);
  const partnerCourses = useSelector((state) => state.partnerInstance.courses?.data);

  React.useEffect(() => {
    dispatch(
      partnerInstanceActions.getPartnerCoursesRequest({
        partnerId: globalPartner.partnerId,
        pageSize: 6,
      }),
    );
  }, [globalPartner.partnerId]);

  const renderCoursesSection = (): React.ReactElement<any> | null => {
    if (!partnerCourses?.length) {
      return null;
    }
    // Sort courses by pusblished date, and display most recent
    // courses (max 6).
    partnerCourses.sort(
      (a, b) => new Date(b.sys.publishedAt).getTime() - new Date(a.sys.publishedAt).getTime(),
    );

    const maxSlice = partnerCourses.length < 6 ? partnerCourses.length : 6;
    const partnerCoursesSlice = partnerCourses.slice(0, maxSlice);

    return (
      <Section>
        <CourseSectionWrapper>
          <SectionTitle>More Than A Piece Of Paper</SectionTitle>
          <SectionSubtitle>
            100% online learning experiences that go beyond the certificate
          </SectionSubtitle>
          <CourseSectionContainer>
            {partnerCoursesSlice.map((course) => (
              <CourseCardContainer>
                <CourseCard
                  key={course.id}
                  id={course.id}
                  title={course.shortTitle}
                  image={course.cardImage?.url}
                  url={`/courses/${course.slug}`}
                  tagName='Course'
                  description=''
                  date={course.startDate}
                  courseLogo={course?.school?.university?.logo?.url}
                  slug={course.slug}
                  checked={false}
                  schoolName={course?.school?.university?.name}
                  hideLearnMore
                  hideSave
                />
              </CourseCardContainer>
            ))}
          </CourseSectionContainer>
          <StyledInternalLink to='/courses/browse'>
            <NooderaButton.CTA>View All Courses</NooderaButton.CTA>
          </StyledInternalLink>
        </CourseSectionWrapper>
      </Section>
    );
  };

  const renderHeroSection = (): React.ReactElement<any> | null => {
    if (width < TABLET_SMALL_WIDTH) {
      return (
        <GuestHomePrivateLabelHeroSection>
          <GuestHeroSectionContainer>
            <LeftHeroContent>
              <HeroSection>
                <BannerTitle>{globalPartner?.heroTitle || guestHomeHeroBlock.TITLE}</BannerTitle>
              </HeroSection>
              <HeroSection>
                <BannerImageContainer>
                  <GuestHomeHeroSvgWrapper>
                    <HeroSmSvg />
                  </GuestHomeHeroSvgWrapper>
                </BannerImageContainer>
              </HeroSection>
            </LeftHeroContent>
            <BannerSubtitle>
              {globalPartner?.heroParagraph || guestHomeHeroBlock.PARAGRAPH}
            </BannerSubtitle>
            <StyledInternalLink to='/courses/browse'>
              <NooderaButton>View All Courses</NooderaButton>
            </StyledInternalLink>
          </GuestHeroSectionContainer>
        </GuestHomePrivateLabelHeroSection>
      );
    }

    return (
      <GuestHomePrivateLabelHeroSection>
        <GuestHeroSectionContainer>
          <LeftHeroContent>
            <HeroSection>
              <BannerTitle>{globalPartner?.heroTitle || guestHomeHeroBlock.TITLE}</BannerTitle>
              <BannerSubtitle>
                {globalPartner?.heroParagraph || guestHomeHeroBlock.PARAGRAPH}
              </BannerSubtitle>
            </HeroSection>
            <HeroSection>
              <StyledInternalLink to='/courses/browse'>
                <NooderaButton>View All Courses</NooderaButton>
              </StyledInternalLink>
            </HeroSection>
          </LeftHeroContent>
          <BannerImageContainer>
            <GuestHomeHeroSvgWrapper>
              <HeroSvg />
            </GuestHomeHeroSvgWrapper>
          </BannerImageContainer>
        </GuestHeroSectionContainer>
      </GuestHomePrivateLabelHeroSection>
    );
  };

  const renderBlockSection = (): React.ReactElement<any> | null => {
    if (width < TABLET_SMALL_WIDTH) {
      return (
        <FirstBlockSection>
          <div>
            <DoItTogetherSectionTitle>
              {globalPartner?.firstBlockTitle || guestHomeFirstBlock.TITLE}
            </DoItTogetherSectionTitle>
            <DoItTogetherSectionText>
              {globalPartner?.firstBlockParagraph || guestHomeFirstBlock.PARAGRAPH}
            </DoItTogetherSectionText>
          </div>
          <div>
            <GuestHomeSvgWrapper>
              <StudentSmSvg />
            </GuestHomeSvgWrapper>
          </div>
        </FirstBlockSection>
      );
    }

    return (
      <FirstBlockSection>
        <div>
          <GuestHomeSvgWrapper>
            <StudentSvg />
          </GuestHomeSvgWrapper>
        </div>
        <div>
          <DoItTogetherSectionTitle>
            {globalPartner?.firstBlockTitle || guestHomeFirstBlock.TITLE}
          </DoItTogetherSectionTitle>
          <DoItTogetherSectionText>
            {globalPartner?.firstBlockParagraph || guestHomeFirstBlock.PARAGRAPH}
          </DoItTogetherSectionText>
        </div>
      </FirstBlockSection>
    );
  };

  return (
    <GuestHomePrivateLabelWrapper role='main'>
      <GuestHomeMetaTags partner={globalPartner} />
      <GuestHomeContainer>
        {renderHeroSection()}
        <SectionsWrapper>
          <SectionsContainer>
            {renderCoursesSection()}
            {renderBlockSection()}
          </SectionsContainer>
        </SectionsWrapper>
      </GuestHomeContainer>
      <FooterPrivateLabel />
    </GuestHomePrivateLabelWrapper>
  );
};

export default GuestHomePrivateLabel;
