import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Auth0Lock from 'auth0-lock';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Country } from 'country-state-city';
import { ICountry } from 'country-state-city/dist/lib/interface';

import store from 'ducks/store';
import authActions from 'ducks/auth/actions';
import { partnerInstanceActions } from 'ducks/partnerInstance/actions';

import { deleteCookie, deletePartnerCookie, getPartnerCookie, setCookie } from 'utils/cookies';
import { getQueryStringParams } from 'utils/string';
import { clearUtmValues, resetHeapIdentify, UTM_VALUES } from 'utils/heap';
import { getSessionStorageData, removeSessionStorageDataStartWith } from 'utils/sessionStorage';
import { setSession } from 'utils/auth';

import { CHROME_COOKIE_MAX_AGE, getSignupTerms, SPACING_TAG } from 'commons/constants';
import { ENROLL_COURSE_PATH } from 'commons/constants/course';
import { AUTH_HELP_TEXT } from 'commons/constants/auth';
import { NOODLE_CART_SESSION_ID_COOKIE_KEY } from 'commons/types';

import { getTheme } from 'settings/theme';
import { Auth0Config, baseUrl, images, moodleUrl } from 'settings/index';

import { getAppSettingForEnvironment, isNonNoodleInstance } from '../privateLabel';

interface AuthContextType {
  auth0Lock: any;
  returnUrl: string;
  logout: (logoutObj: { logoutSuccess?: boolean; partnerId: string }) => void;
  isAuthenticated: (partnerId: string) => boolean;
  lockEmailField: () => void;
  setAuthHelpTextHTML: (text: string) => void;
  configureAuth0Lock: () => typeof Auth0Lock;
  removeBrowserUserData: (partnerId: string) => void;
}

// This happens on page load/refresh. Email query params are not appended dynamically, so there shouldn't be any issues with the current implementation.
// TODO: We have to test this before changing it!
// eslint-disable-next-line prefer-regex-literals
const authPathsRegex = new RegExp('login|signup|password-reset');
const nameRegex = /^[a-zA-Z'-]*$/;
const isAuthLocation = authPathsRegex.test(window.location.pathname);
const prefilledEmail: string = isAuthLocation
  ? decodeURIComponent(getQueryStringParams('email') || '')
  : '';

const getBaseOptions: any = () => {
  const utmParams = getSessionStorageData(UTM_VALUES);
  const {
    partnerInstance: {
      globalPartner: { data: globalPartner },
    },
  } = store.getState();

  const signUpTerms = getSignupTerms(globalPartner?.termsOfUseLink);
  const countries = Country.getAllCountries().map((country: ICountry) => ({
    label: country?.name,
    value: country?.isoCode,
  }));

  const privateLabelAllowedConnections = [Auth0Config.realm];

  const hasExternalIdp = globalPartner?.hasExternalIdp;
  const auth0EnterpriseConnectionName = globalPartner?.auth0EnterpriseConnectionName;
  if (hasExternalIdp && auth0EnterpriseConnectionName) {
    privateLabelAllowedConnections.push(auth0EnterpriseConnectionName);
  }

  return {
    configurationBaseUrl: 'https://cdn.auth0.com',
    auth: {
      audience: Auth0Config.audience,
      sso: true,
      responseType: 'token id_token',
      scope: 'openid email profile',
      redirectUrl: Auth0Config.callbackUrl,
    },
    allowedConnections: isNonNoodleInstance ? privateLabelAllowedConnections : [],
    additionalSignUpFields: [
      {
        name: 'firstName',
        placeholder: 'First Name*',
        validator: (firstName: string) => ({
          valid: firstName.length > 0 && firstName.length <= 16 && nameRegex.test(firstName),
          hint: 'First name should be 1-16 characters and may not contain special characters or numbers',
        }),
      },
      {
        name: 'middleName',
        placeholder: 'Middle Name (Optional)',
        validator: (middleName: string) => ({
          valid: middleName.length <= 16 && nameRegex.test(middleName),
          hint: 'Middle name should not be above 16 characters and may not contain special characters or numbers',
        }),
      },
      {
        name: 'lastName',
        placeholder: 'Last Name*',
        validator: (lastName: string) => ({
          valid: lastName.length > 0 && lastName.length <= 16 && nameRegex.test(lastName),
          hint: 'Last name should be 1-16 characters and may not contain special characters or numbers',
        }),
      },
      {
        type: 'select',
        name: 'country',
        placeholder: 'Country (optional)',
        options: countries,
        prefill: 'US',
      },
      {
        name: 'phoneNumber',
        placeholder: 'Phone Number (Optional)',
        validator: (phoneNumber: string) => {
          // @ts-ignore
          const selectedCountryName = document.getElementById('1-country')?.value;
          const country = countries.find(
            (countryValue) => countryValue.label === selectedCountryName,
          );
          return {
            // @ts-ignore
            valid: phoneNumber.length === 0 || isPossiblePhoneNumber(phoneNumber, country?.value),
            hint: 'Phone number is invalid',
          };
        },
      },
      {
        type: 'hidden',
        name: 'utmSource',
        value: utmParams?.utm_source || '',
      },
      {
        type: 'hidden',
        name: 'utmMedium',
        value: utmParams?.utm_medium || '',
      },
      {
        type: 'hidden',
        name: 'utmCampaign',
        value: utmParams?.utm_campaign || '',
      },
      {
        type: 'hidden',
        name: 'utmTerm',
        value: utmParams?.utm_term || '',
      },
      {
        type: 'hidden',
        name: 'utmContent',
        value: utmParams?.utm_content || '',
      },
    ],
    theme: {
      logo: images.noodleLogo,
      primaryColor: getTheme().palette.noodle.primary,
    },
    forgotPasswordLink: '/password-reset',
    languageDictionary: {
      title: 'Noodle',
      signUpTerms,
      emailInputPlaceholder: 'Email Address*',
      passwordInputPlaceholder: 'Password*',
      enterpriseLoginIntructions: '',
      loginAtLabel: 'Continue at %s',
    },
    container: 'auth-content',
    prefill: {
      email: prefilledEmail,
    },
    mustAcceptTerms: signUpTerms !== SPACING_TAG,
  };
};

export const isAuthenticated = (partnerId: string): boolean => {
  const expiresAt = JSON.parse(getPartnerCookie(`expired_time`, partnerId));
  return new Date().getTime() < +expiresAt;
};

export const AuthContext: any = React.createContext<Partial<AuthContextType>>({
  auth0Lock: {},
});

export const AuthProvider = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const { data: globalPartner } = useSelector((state: any) => state.partnerInstance.globalPartner);

  React.useEffect(() => {
    dispatch(partnerInstanceActions.getGlobalPartnerRequest());
  }, []);

  let returnUrl = getQueryStringParams('returnUrl');
  let auth0Lock: typeof Auth0Lock;

  const lockEmailField = () => {
    const theme = getTheme();

    const lockField = (): void => {
      // Replacing querySelector type declaration since flow does not recognize readOnly attribute :)
      const emailField: Record<string, any> = document.querySelector('input[name="email"]');
      const emailIconContainer: Record<string, any> =
        document.querySelector('.auth0-lock-input-email');

      if (emailField && emailIconContainer) {
        const emailFieldStyle: Record<string, any> = emailField.style;
        const emailIconContainerStyle: Record<string, any> = emailIconContainer.style;
        emailField.readOnly = true;
        emailFieldStyle.background = theme.palette.neutral.fifteen;
        emailIconContainerStyle.background = theme.palette.neutral.fifteen;
      }
    };

    auth0Lock.on('signin ready', () => {
      lockField();
    });
    auth0Lock.on('signup ready', () => {
      lockField();
    });
  };

  const setAuthHelpTextHTML = (text: string): void => {
    const formContainer: HTMLElement | null =
      document.querySelector('.auth0-lock-input-email')?.parentElement;

    if (!formContainer) return;

    const authHelpText: HTMLElement | null = document.querySelector('.auth-help-text');
    const authTabsContainer: HTMLElement | null = document.querySelector(
      '.auth0-lock-tabs-container',
    );

    // First run, we just instantiate the help text
    if (!authHelpText) {
      formContainer.insertAdjacentHTML('afterbegin', `<p class='auth-help-text'>${text}</p>`);
      // Different spacing rules when help text is present, so overriding the default
      authTabsContainer.classList.add('auth0-lock-tabs-container-with-help-text');
    }

    // If the help text has already been instantiated, we just update the text
    if (authHelpText && !authHelpText?.innerText?.includes(text)) {
      authHelpText.innerText = text;
      // Different spacing rules when help text is present, so overriding the default
      authTabsContainer.classList.add('auth0-lock-tabs-container-with-help-text');
    }
  };

  const removeBrowserUserData = (partnerId: string): void => {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('getStreamUserToken');
    localStorage.removeItem('capacityUserToken');

    removeSessionStorageDataStartWith(['ais-concierge']);

    deleteCookie('access_token');
    deleteCookie('userId');
    deleteCookie('expires_at');
    deleteCookie('expired_time');
    deleteCookie('partnerId');
    deleteCookie(NOODLE_CART_SESSION_ID_COOKIE_KEY);
    deletePartnerCookie(partnerId, NOODLE_CART_SESSION_ID_COOKIE_KEY);

    // Delete the partner's individual access token to support separate sessions
    deletePartnerCookie(partnerId, 'access_token');
    deletePartnerCookie(partnerId, 'expires_at');
    deletePartnerCookie(partnerId, 'expired_time');

    clearUtmValues();
    resetHeapIdentify();
  };

  const logout = ({
    logoutSuccess,
    partnerId,
  }: {
    logoutSuccess: boolean;
    partnerId: string;
  }): void => {
    removeBrowserUserData(partnerId);
    setCookie('partnerUrl', String(baseUrl), CHROME_COOKIE_MAX_AGE);

    auth0Lock = new Auth0Lock(
      isNonNoodleInstance
        ? globalPartner.auth0ClientId
        : getAppSettingForEnvironment('auth0ClientId'),
      Auth0Config.domain,
      getBaseOptions(),
    );
    // Redirects after discovery logout to Moodle, for logout there. Then redirects back to discovery.
    // The custom microsite is favored for redirect, then the vanity url, and then PartnerUrl
    // cookie will be used as default.
    let logoutRedirect = '';

    if (globalPartner?.isMicrositeLandingEnabled && globalPartner?.micrositeLandingPageUrl) {
      logoutRedirect = `?next=${globalPartner?.micrositeLandingPageUrl}`;
    } else if (globalPartner?.vanityUrlEnabled && globalPartner?.vanityUrl) {
      logoutRedirect = `?next=${globalPartner?.vanityUrl}`;
    }

    // Erase cookies in both domains and finally logout from Auth0 using the logout page.
    if (globalPartner?.vanityUrlEnabled) {
      const isNoodleDomain = window.location.hostname.includes('.noodle.com');
      const url = `${isNoodleDomain ? globalPartner.vanityUrl : globalPartner.frontDomain}/logout`;

      // If vanity url...
      if (!isNoodleDomain) {
        // and logoutSuccess is true, meaning they've logged out of both domains.
        // We call the auth0 logout endpoint and redirect to the vanity domain homepage.
        if (logoutSuccess) {
          const returnTo = `${moodleUrl}/local/nlpservices/signout.php${logoutRedirect}`;

          auth0Lock.logout({
            returnTo,
          });
          // If logoutSuccess is false, meaning they haven't logged out of the .noodle domain, we send the user to
          // the .noodle logout page.
        } else {
          window.location.replace(url);
        }
        // If .noodle domain, we send the user to the vanity url domain and append the logout_success query param.
      } else {
        window.location.replace(`${url}?logout_success=1`);
      }
      // If vanity url is not enabled, we just logout from Auth0 and redirect to the noodle domain homepage.
    } else {
      const returnTo = `${moodleUrl}/local/nlpservices/signout.php${logoutRedirect}`;

      auth0Lock.logout({
        returnTo,
      });
    }
  };

  const reRenderAuthComponent = (
    authType: string,
    initialScreen: 'login' | 'signUp' | 'forgotPassword',
  ) => {
    returnUrl = getQueryStringParams('returnUrl');
    const searchParams = new URLSearchParams(window.location.search);

    // Check if the 'returnUrl' parameter is not present at the time of auth0 initialization
    // for some reason.
    if (!searchParams.has('returnUrl') && returnUrl) {
      searchParams.set('returnUrl', returnUrl);
    }

    const searchParamsString = searchParams.toString();
    const fullQueryString = searchParamsString ? `?${searchParamsString}` : '';

    const newAuthUrl = `/${authType}${fullQueryString}`;

    auth0Lock.hide();
    auth0Lock.show({
      auth: {
        params: {
          state: JSON.stringify({
            authType,
            returnUrl,
          }),
        },
      },
      initialScreen,
    });
    window.history.pushState({}, '', newAuthUrl);
    // Making sure padding is correct when changing from login to signup
    if (isNonNoodleInstance) {
      const formContainer: Record<string, any> =
        document.querySelector('.auth0-lock-input-email')?.parentElement;
      formContainer.classList.add('auth0-lock-form-container');
    }
  };

  const configureAuth0Lock = (): typeof Auth0Lock => {
    auth0Lock = new Auth0Lock(
      isNonNoodleInstance
        ? globalPartner.auth0ClientId
        : getAppSettingForEnvironment('auth0ClientId'),
      Auth0Config.domain,
      getBaseOptions(),
    );

    auth0Lock.on('authenticated', (authResult) => {
      auth0Lock.getUserInfo(authResult.accessToken, (err, profile) => {
        if (err) {
          console.error(
            '🚀 ~ file: index.js ~ line 26 ~ Auth0 ~ this.auth0.getUserInfo ~ err',
            err,
          );
          return;
        }

        const enrollCoursePath = getSessionStorageData(ENROLL_COURSE_PATH);
        const authState = JSON.parse(authResult?.state);

        // We get the DB user id coming back from the auth0 rule.
        const loginCount = profile[Auth0Config.userMetadataKey]?.login_count;
        // Set the user session
        setSession(authResult, globalPartner?.partnerId);
        // Log in the user by fetching their user data. This works for both login and signup
        const onboardingUrl = `/onboarding${
          authState?.returnUrl ? `?returnUrl=${authState?.returnUrl}` : ''
        }`;
        dispatch(
          authActions.loginRequest({
            guestRoute: false,
            pathname:
              (loginCount <= 1 && globalPartner?.hasOnboardingSurvey
                ? onboardingUrl
                : decodeURIComponent(authState?.returnUrl) || '/') ||
              (authState?.returnUrl && decodeURIComponent(authState?.returnUrl)) ||
              enrollCoursePath,
            logout,
          }),
        );
      });
    });

    auth0Lock.on('authorization_error', (error) => {
      if (error.error === 'unauthorized') {
        // Redirect using the browser so we don't have a race condition between login and history.push
        window.location.href = '/forbidden';
      }
      console.error('🚀 ~ file: customHooks.js ~ line 251 ~ AuthProvider ~ error', error);
    });

    auth0Lock.on('signup ready', (error) => {
      // This is because we need to know what the op is to dispatch the appropriate actions to the BE
      if (error) {
        console.error('🚀 ~ file: auth.js ~ line 84 ~ AuthProvider ~ error', error);
      }

      if (window.location.pathname.includes('signup')) {
        return;
      }

      reRenderAuthComponent('signup', 'signUp');

      if (returnUrl?.includes('cartSessionId')) {
        setAuthHelpTextHTML(AUTH_HELP_TEXT.SIGNUP);
      }
    });

    auth0Lock.on('signin ready', (error) => {
      // This is because we need to know what the op is to dispatch the appropriate actions to the BE
      if (error) {
        console.error('🚀 ~ file: auth.js ~ line 95 ~ AuthProvider ~ error', error);
      }

      if (window.location.pathname.includes('login')) {
        return;
      }

      reRenderAuthComponent('login', 'login');

      if (returnUrl?.includes('cartSessionId')) {
        setAuthHelpTextHTML(AUTH_HELP_TEXT.LOGIN);
      }
    });

    return auth0Lock;
  };

  const providerValue = React.useMemo(
    () => ({
      auth0Lock,
      logout,
      isAuthenticated,
      lockEmailField,
      setAuthHelpTextHTML,
      configureAuth0Lock,
      returnUrl,
      removeBrowserUserData,
    }),
    [
      auth0Lock,
      logout,
      isAuthenticated,
      lockEmailField,
      setAuthHelpTextHTML,
      configureAuth0Lock,
      returnUrl,
      removeBrowserUserData,
    ],
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => React.useContext(AuthContext);
