// @ts-nocheck
import styled from 'styled-components';
import type { StatelessFunctionalComponent } from 'react';
import { getTheme } from 'settings/theme';
import { TABLET_SMALLER_WIDTH } from 'commons/constants';

const { spacing } = getTheme();
const SimpleCardWrapper: StatelessFunctionalComponent<any> = styled.div`
  background-color: ${(props) => props.backgroundColor};
  padding: ${spacing.x4};
  border-radius: 0.6rem;
  height: ${(props) => `${props.height}px`};

  .simple-card {
  }

  @media only screen and (max-width: ${TABLET_SMALLER_WIDTH}px) {
    .simple-card {
    }
  }
`;
export default SimpleCardWrapper;
