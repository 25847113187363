import * as React from 'react';
import { University as UniversityType } from 'commons/types/courseTypes';

import {
  Container,
  LogoContainer,
  DescriptionContainer,
  Title,
  Name,
  StyledImage,
} from './University.styles';

interface UniversityProp {
  university: UniversityType;
  orientation?: string;
  className?: string;
}

const University: React.FC<UniversityProp> = ({ university, orientation, className }) => {
  const {
    title = 'offered by',
    name = 'University of Michigan',
    logo = {
      url: '',
    },
  } = university ?? {};
  return (
    <Container orientation={orientation} className={className || ''}>
      <LogoContainer orientation={orientation}>
        <StyledImage alt='' src={logo?.url} width='100%' height='100%' />
      </LogoContainer>
      <DescriptionContainer orientation={orientation}>
        <div>
          <Title> {title}</Title>
        </div>
        <div>
          <Name>{name}</Name>
        </div>
      </DescriptionContainer>
    </Container>
  );
};

export default University;
