// @ts-nocheck
/* eslint-disable no-underscore-dangle */
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import { rtkQApi } from 'lib/Api';
import { alertTypesConstants, CHROME_COOKIE_MAX_AGE } from 'commons/constants';

import { setPartnerCookie } from 'utils/cookies';
import { NOODLE_CART_SESSION_ID_COOKIE_KEY } from 'commons/types/shoppingCartTypes';
import dashboardActions from 'ducks/dashboard/actions';
import alertActions from './alert/actions';
import { usersActions } from './user/actions';
import { extractErrorMessage } from '../utils/errorHelpers';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.shareCourseToFedNetwork.matchRejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'There was an error sharing your course to the federated network.',
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.unshareCourseFromFedNetwork.matchRejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'There was an error unsharing your course from the federated network.',
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateChildInstanceCourses.matchRejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'There was an error while saving your changes.',
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.shareCourseToFedNetwork.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully shared a Course',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.unshareCourseFromFedNetwork.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully unshared a Course',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateChildInstanceCourses.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully saved the changes',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateCourseNetworkPrice.matchFulfilled,
  effect: async (action, listenerApi) => {
    const {
      payload: { partnerId, slug },
    } = action;

    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You changed the price for this network course',
        type: alertTypesConstants.SUCCESS,
      }),
    );
    listenerApi.dispatch(push(`/instance/${partnerId}/programs/${slug}`));
  },
});
listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updatePartnerCourse.matchFulfilled,
  effect: async (action, listenerApi) => {
    const {
      payload: {
        partner: { partnerId },
        slug,
      },
    } = action;

    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You changed the price for this course',
        type: alertTypesConstants.SUCCESS,
      }),
    );
    listenerApi.dispatch(push(`/instance/${partnerId}/programs/${slug}`));
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.getShoppingCart.matchFulfilled,
  effect: async (action, listenerApi) => {
    const {
      payload: { sessionId, isTemporary, hasRemovedInvalidCartItems, error, partnerId },
    } = action;

    if (sessionId && !isTemporary) {
      setPartnerCookie(
        partnerId,
        NOODLE_CART_SESSION_ID_COOKIE_KEY,
        sessionId,
        CHROME_COOKIE_MAX_AGE,
      );
    }
    if (hasRemovedInvalidCartItems) {
      const errorMessage =
        error ||
        "Courses you're already enrolled in, or have reached their enrollment capacity have been removed from your shopping cart.";
      listenerApi.dispatch(
        alertActions.setAlert({
          // You can't add any courses from the FE that are fed-net or canvas, so that only leaves
          // already enrolled-in courses or courses at their max capacity.
          message: errorMessage,
          type: alertTypesConstants.SUCCESS,
        }),
      );
    }
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.addCartItemToShoppingCart.matchFulfilled,
  effect: async (action, listenerApi) => {
    if (!action.payload.isTemporary) {
      listenerApi.dispatch(
        alertActions.setAlert({
          message: 'You successfully added an item to your cart',
          type: alertTypesConstants.SUCCESS,
        }),
      );
    }
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.applyCouponToShoppingCart.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully redeemed a code',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateUserProfile.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully saved your changes',
        type: alertTypesConstants.SUCCESS,
      }),
    );
    listenerApi.dispatch(usersActions.getOwnUserRequest());
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(
    rtkQApi.endpoints.getShoppingCart.matchRejected,
    rtkQApi.endpoints.addCartItemToShoppingCart.matchRejected,
    rtkQApi.endpoints.updateCartItemFromShoppingCart.matchRejected,
    rtkQApi.endpoints.deleteCartItemFromShoppingCart.matchRejected,
  ),
  effect: async (action, listenerApi) => {
    const response = action?.payload;

    if (response) {
      listenerApi.dispatch(
        alertActions.setAlert({
          message: JSON.stringify(response?.data?.detail),
          type: alertTypesConstants.DANGER,
        }),
      );
    }
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.createInvoiceFromShoppingCart.matchFulfilled,
  effect: async (action, listenerApi) => {
    const response = action?.payload;

    if (response) {
      // Edge case: Stripe Invoices for $0 are automatically
      // generated as 'paid'. If paid, route back to home,
      // rather than checkout
      const { invoice, isPaid, invalidCartItems } = response;
      if (invoice && isPaid) {
        listenerApi.dispatch(push(`/`));
        listenerApi.dispatch(dashboardActions.setModalAction('shoppingCartSuccess'));
      } else if (!invoice) {
        // Edge Case, All cart itens were invalid and cart is now Empty. Route back
        // to shopping cart
        listenerApi.dispatch(push(`/cart`));
        listenerApi.dispatch(
          alertActions.setAlert({
            message:
              'Courses in you cart were not valid, or have reached their enrollment capacity and have been removed.',
            type: alertTypesConstants.WARNING,
          }),
        );
      } else if (invalidCartItems) {
        listenerApi.dispatch(
          alertActions.setAlert({
            message:
              "Courses you've enrolled in, or that were not valid, or have reached their enrollment capacity have been removed",
            type: alertTypesConstants.WARNING,
          }),
        );
      }
    }
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.createInvoiceFromShoppingCart.matchRejected,

  effect: async (action, listenerApi) => {
    const response = action?.payload;

    if (response) {
      listenerApi.dispatch(
        alertActions.setAlert({
          message: JSON.stringify(response?.data?.detail),
          type: alertTypesConstants.DANGER,
        }),
      );
    }
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.completeShoppingCartCheckout.matchRejected,

  effect: async (action, listenerApi) => {
    const response = action?.payload;

    if (response) {
      listenerApi.dispatch(
        alertActions.setAlert({
          message: JSON.stringify(response?.data?.detail),
          type: alertTypesConstants.DANGER,
        }),
      );
    }
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.addUserToCourseWaitlist.matchFulfilled,
  effect: async (action, listenerApi) => {
    const requestedAddedUserIdsAlreadyOnWaitlist =
      action?.payload?.requestedAddedUserIdsAlreadyOnWaitlist;

    if (requestedAddedUserIdsAlreadyOnWaitlist.length > 0) {
      listenerApi.dispatch(
        alertActions.setAlert({
          message: 'There was an error while attempting to add you to the Course Waitlist ',
          type: alertTypesConstants.DANGER,
        }),
      );
    } else {
      listenerApi.dispatch(dashboardActions.setModalAction('userAddedToWaitlist'));
    }
  },
});
listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.removeUserFromCourseWaitlist.matchFulfilled,
  effect: async (action, listenerApi) => {
    const requestedRemovedUserIdsNotOnWaitlist =
      action?.payload?.requestedRemovedUserIdsNotOnWaitlist;

    if (requestedRemovedUserIdsNotOnWaitlist.length > 0) {
      listenerApi.dispatch(
        alertActions.setAlert({
          message: 'There was an error while attempting to remove you from the Course Waitlist.',
          type: alertTypesConstants.DANGER,
        }),
      );
    } else {
      listenerApi.dispatch(dashboardActions.setModalAction('userRemovedFromWaitlist'));
    }
  },
});
listenerMiddleware.startListening({
  matcher: isAnyOf(
    rtkQApi.endpoints.addUserToCourseWaitlist.matchRejected,
    rtkQApi.endpoints.removeUserFromCourseWaitlist.matchRejected,
  ),
  effect: async (action, listenerApi) => {
    const response = action?.payload;

    if (response) {
      listenerApi.dispatch(
        alertActions.setAlert({
          message: JSON.stringify(response?.data?.detail),
          type: alertTypesConstants.DANGER,
        }),
      );
    }
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateUserProfile.matchRejected,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'There was an error while attempting to update your profile',
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

/* This doesn't take into account admin purchases, this is solely for user self enrollments */
listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.createStripeCourseInvoice.matchFulfilled,
  effect: async (action, listenerApi) => {
    const response = action?.payload;

    if (response.alreadyPaid) {
      // Only show this alert if it's a paid course:
      if (response?.amountDue || response?.amountPaid) {
        listenerApi.dispatch(
          alertActions.setAlert({
            message: "You've already paid for this course",
            type: alertTypesConstants.SUCCESS,
          }),
        );
      }
    }
  },
});
listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteUserCourseEnrollment.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully unenrolled a learner',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});
listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteUserCourseEnrollment.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    listenerApi.dispatch(
      alertActions.setAlert({
        message: response?.data?.detail
          ? JSON.stringify(response?.data?.detail)
          : 'There was an error while attempting to unenroll the learner',
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.createInstructor.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully created an instructor',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.createInstructor.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    let message = 'There was an error creating the instructor';
    if (response?.data?.detail) {
      // show one error at a time
      const errorKey = Object.keys(response?.data?.detail)[0];
      message = response?.data?.detail[errorKey][0];
    }
    listenerApi.dispatch(
      alertActions.setAlert({
        message,
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateInstructor.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully edited an instructor',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateInstructor.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    const message: string = extractErrorMessage(response);
    listenerApi.dispatch(
      alertActions.setAlert({
        message,
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteInstructor.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'Instructor successfully deleted',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteInstructor.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    const message: string = extractErrorMessage(response);
    listenerApi.dispatch(
      alertActions.setAlert({
        message,
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteContentProvider.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'Content provider successfully deleted',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteContentProvider.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    const message: string = extractErrorMessage(response);
    listenerApi.dispatch(
      alertActions.setAlert({
        message,
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.createDonationAsk.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully created a donation',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.createDonationAsk.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    let message = 'There was an error creating the donation';
    if (response?.data?.detail) {
      // show one error at a time
      const errorKey = Object.keys(response?.data?.detail)[0];
      message = response?.data?.detail[errorKey][0];
    }
    listenerApi.dispatch(
      alertActions.setAlert({
        message,
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateDonationAsk.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'You successfully edited a donation',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.updateDonationAsk.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    const message: string = extractErrorMessage(response);
    listenerApi.dispatch(
      alertActions.setAlert({
        message,
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteDonationAsk.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(
      alertActions.setAlert({
        message: 'Donation successfully deleted',
        type: alertTypesConstants.SUCCESS,
      }),
    );
  },
});

listenerMiddleware.startListening({
  matcher: rtkQApi.endpoints.deleteDonationAsk.matchRejected,
  effect: async (action, listenerApi) => {
    const response = action?.payload;
    const message: string = extractErrorMessage(response);
    listenerApi.dispatch(
      alertActions.setAlert({
        message,
        type: alertTypesConstants.DANGER,
      }),
    );
  },
});

export default listenerMiddleware;
