import * as React from 'react';
import Lottie from 'react-lottie';

// Components
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from 'components/AppBar';
import NooderaButton from 'components/NooderaButton';

import {
  Content,
  SmallContent,
  ModalContent,
  ModalTitle,
  ModalImage,
  ModalDescription,
  ModalButtons,
  ModalLinkText,
  StyledDialog,
  MediumDialog,
  StyledDialogTitle,
  StyledDialogActions,
  SmallModalTopIcon,
  ModalCloseIconWrapper,
  DefaultDialog,
} from './NooderaModal.styles';

interface NooderaModalBaseProps {
  isOpen: boolean;
  title?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  role?: string;
  onClose?: (...args: Array<any>) => any;
}
interface NooderaModalProps extends NooderaModalBaseProps {
  hasAppBar?: boolean;
  children: any;
}
interface NooderaSmallModalProps extends NooderaModalBaseProps {
  icon: React.ReactElement;
  description: string | React.ReactElement;
  imageContent?: any;
  className?: string;
  linkText?: string;
  btnText?: string;
  customBtns?: React.ReactElement | React.ReactElement[];
  hasCloseBtn?: boolean;
  isButtonDisabled?: boolean;
  onBtnClick?(): void;
}
interface NooderaMediumModalProps extends NooderaModalBaseProps {
  className?: string;
  children: React.ReactElement | React.ReactElement[] | string;
  footer?: React.ReactElement | React.ReactElement[] | string;
}

interface NooderaModalSubcomponents {
  Small: React.FC<NooderaSmallModalProps>;
  Medium: React.FC<NooderaMediumModalProps>;
}

const NooderaModal: React.FC<NooderaModalProps> & NooderaModalSubcomponents = ({
  isOpen,
  title = '',
  hasAppBar = true,
  children,
  onClose,
}) => (
  <DefaultDialog
    fullScreen
    open={isOpen}
    onClose={onClose}
    role='dialog'
    aria-modal='true'
    aria-live='polite'
    aria-label={title}
  >
    {hasAppBar && <AppBar title={title} onClose={onClose} />}
    <Content hasAppBar={hasAppBar}>{children}</Content>
  </DefaultDialog>
);

const Small: React.FC<NooderaSmallModalProps> = ({
  isOpen,
  icon,
  title,
  description,
  imageContent,
  className,
  linkText,
  btnText,
  onClose,
  onBtnClick,
  customBtns,
  hasCloseBtn,
  isButtonDisabled,
  role,
}) => {
  const baseId = 'modal';
  const modalTitleId = `${baseId}-title-${title.replace(/\s/g, '')}`;
  const modalDescriptionId = `${baseId}-description-${title.replace(/\s/g, '')}`;

  return (
    <StyledDialog
      className={className}
      open={isOpen}
      onClose={onClose}
      role={role ?? 'alertdialog'}
      aria-live='assertive'
      aria-modal='true'
      aria-labelledby={modalTitleId}
      aria-describedby={modalDescriptionId}
    >
      {hasCloseBtn && (
        <ModalCloseIconWrapper>
          <NooderaButton.RoundCTA onClick={onClose} size='small' aria-label='Close'>
            <CloseIcon />
          </NooderaButton.RoundCTA>
        </ModalCloseIconWrapper>
      )}
      <SmallContent>
        <ModalContent>
          <SmallModalTopIcon>{icon}</SmallModalTopIcon>
          <ModalTitle id={modalTitleId}>{title}</ModalTitle>
          {imageContent && (
            <ModalImage aria-hidden='true'>
              <Lottie
                className='lottie'
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: imageContent,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </ModalImage>
          )}
          <ModalDescription id={modalDescriptionId}>
            <div>
              {description} {linkText ? <ModalLinkText>{linkText}</ModalLinkText> : null}
            </div>
          </ModalDescription>
        </ModalContent>
        <ModalButtons>
          {!customBtns ? (
            <NooderaButton.CTA
              size='large'
              onClick={onBtnClick ?? onClose}
              isDisabled={isButtonDisabled}
              aria-label={btnText}
              tabIndex={0}
            >
              {btnText}
            </NooderaButton.CTA>
          ) : (
            customBtns
          )}
        </ModalButtons>
      </SmallContent>
    </StyledDialog>
  );
};

const Medium: React.FC<NooderaMediumModalProps> = ({
  isOpen,
  title,
  children,
  footer,
  onClose,
  className,
  ...extraProps
}) => (
  <MediumDialog
    className={className}
    open={isOpen}
    onClose={onClose}
    {...extraProps}
    role='dialog'
    aria-modal='true'
    aria-live='polite'
    aria-labelledby={`title-${title.replace(/\s/g, '')}`}
  >
    <StyledDialogTitle
      id={`title-${title.replace(/\s/g, '')}`}
      sx={{
        m: 0,
        p: 2,
      }}
    >
      {title}
      {onClose ? (
        <NooderaButton.RoundCTA size='small' onClick={onClose} aria-label='close'>
          <CloseIcon />
        </NooderaButton.RoundCTA>
      ) : null}
    </StyledDialogTitle>
    <DialogContent dividers>{children}</DialogContent>
    {footer && <StyledDialogActions>{footer}</StyledDialogActions>}
  </MediumDialog>
);

NooderaModal.Small = Small;
NooderaModal.Medium = Medium;

export default NooderaModal;
