import styled from 'styled-components';
import { Dropdown } from 'components/Dropdown';
import { TABLET_WIDTH, TABLET_SMALL_WIDTH } from 'commons/constants/size';
import { getTheme } from 'settings/theme/index';
import LaunchIcon from '@mui/icons-material/Launch';

const {
  palette: { greyscale, primary, neutral },
  noodera: { fontTypes, spacing },
  ellipsis,
} = getTheme();

export const MobileHeaderContainer = styled.nav`
  min-height: 48px;
  padding: 0 ${spacing.x9} 0 ${spacing.x6};
  position: sticky;
  top: 0;
  z-index: 3;
  background: ${greyscale.white};
`;

export const SelectContainer = styled.div`
  margin-left: ${spacing.x6};
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  max-width: 60%;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    display: flex;
    flex-grow: 1;
  }
`;

export const LogoLink = styled.a`
  z-index: 1;

  & svg {
    height: 24px;
    width: auto;
  }

  p {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    color: ${neutral.five};
  }
`;

export const StyledLogo = styled.img`
  max-height: 4.2rem;
`;

export const StyledLaunchIcon = styled(LaunchIcon)`
  &&& {
    font-size: 1.6rem;
    margin-right: ${spacing.x7};

    path {
      fill: ${primary.hundred};
    }
  }
`;

export const Heading = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    align-items: center;
    h1 {
      position: static;
      display: inline-block;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > div {
    height: auto;
  }
`;

export const LoadingText = styled.span`
  ${fontTypes.font3};
  color: ${neutral.hundred};
`;

export const MobileHeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;

  & > div,
  & > a > div {
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg path {
    fill: ${neutral.eighty};
  }

  a:hover,
  a:focus,
  a:active {
    svg path {
      fill: ${primary.forty};
    }
  }
`;

export const StyledDropdown = styled(Dropdown)<{
  hideIcon?: string;
}>`
  color: ${neutral.eighty};

  div {
    margin-bottom: unset;
  }
  svg {
    display: ${(props) => (props.hideIcon ? 'none' : 'block')};

    path {
      fill: ${neutral.eighty};
    }
  }
`;

export const CompanyLogo = styled.img`
  max-height: 36px;
  width: auto;
`;

export const AuthButtonWrapper = styled.div`
  @media only screen and (max-width: ${TABLET_WIDTH}px) {
    &&& {
      & > button {
        padding-left: ${spacing.x10};
        padding-right: ${spacing.x10};
      }
    }
  }
`;

export const Text = styled.p`
  && {
    ${ellipsis(1)}
  }
`;
