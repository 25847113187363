import styled from 'styled-components';
import type { FunctionComponent } from 'react';
import { TABLET_SMALL_WIDTH } from 'commons/constants/size';

export const Container: FunctionComponent<any> = styled.div`
  width: 704px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.noodera.spacing.x4};
  align-items: center;

  @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
    width: 450px;
    padding: ${(props) => props.theme.noodera.spacing.x5};
  }
`;
export const ModalFooter: FunctionComponent<any> = styled.div`
  ${(props) => props.theme.noodera.fontTypes.font4Medium};
  color: ${(props) => props.theme.palette.greyscale.smoke};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SectionCardItemWrapper: FunctionComponent<any> = styled.div`
  && {
    text-align: left;
    margin: ${(props) => props.theme.noodera.spacing.x8};

    @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
      & > div {
        margin: 0 -${(props) => props.theme.noodera.spacing.x5} 0 0;
      }
    }
  }
`;
export const Title: FunctionComponent<any> = styled.div`
  && {
    ${(props) => props.theme.noodera.fontTypes.font3Medium};
    color: ${(props) => props.theme.palette.neutral.hundred};
  }
`;
export const TitleContainer: FunctionComponent<any> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.noodera.spacing.x5};
  gap: ${(props) => props.theme.noodera.spacing.x5};
`;
export const Subtitle: FunctionComponent<any> = styled.div`
  display: grid;
  gap: ${(props) => props.theme.noodera.spacing.x7};

  && {
    ${(props) => props.theme.noodera.fontTypes.font5};
  }
`;
export const SwiperContainer: FunctionComponent<any> = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const TextBold: FunctionComponent<any> = styled.span`
  && {
    ${(props) => props.theme.noodera.fontTypes.font4Medium};
  }
`;
export const Wrapper: FunctionComponent<any> = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;

  && {
    max-width: 1024px;
    margin: 0 auto;
    gap: ${(props) => props.theme.noodera.spacing.x4};

    @media only screen and (max-width: ${TABLET_SMALL_WIDTH}px) {
      margin: 0 0 ${(props) => props.theme.noodera.spacing.x3};
    }
  }
`;
