import * as React from 'react';
import NooderaSwiper from 'components/NooderaSwiper';
import Modal from 'components/NooderaModal';
import {
  Container,
  ModalFooter,
  SectionCardItemWrapper,
  Subtitle,
  SwiperContainer,
  TextBold,
  Title,
  TitleContainer,
  Wrapper,
} from 'components/JoinTeamModal/JoinTeamModal.styles';
import { Section } from 'commons/types/sectionTypes';
import LinkButton from 'components/LinkButton';
import Text from 'components/uielements/Text';
import {
  useAddCartItemToShoppingCartMutation,
  useGetPartnerCoursePreviewQuery,
  useGetShoppingCartQuery,
} from 'lib/Api';
import { CartItemTypes, TABLET_SMALL_WIDTH, TABLET_SMALLER_WIDTH } from 'commons/constants';
import { useWidth } from 'utils/customHooks';
import { Course } from 'commons/types/courseTypes';
import { pluralize } from 'utils/string';
import { SwiperSlide } from 'swiper/react';
import Skeleton from '../Skeleton';
import { StyledSectionCard } from './SectionCard/SectionCard.styles';
import SectionCard from './SectionCard';

interface JoinTeamModalProps {
  courseData: Course;
  onClose: () => void;
  onComplete: () => void;
  isOpen: boolean;
  useTempCart: boolean;
}

const JoinTeamModal = ({
  courseData,
  onClose,
  onComplete,
  isOpen,
  useTempCart,
}: JoinTeamModalProps): React.ReactElement<any> => {
  const width = useWidth();

  const { data: shoppingCartData } = useGetShoppingCartQuery({ isTemporary: useTempCart });
  const { data: course, isLoading: isCourseLoading } = useGetPartnerCoursePreviewQuery({
    slug: courseData.slug,
    include: 'sections',
    excludeAttributes: 'course_completion',
  });
  const [addCartItemToShoppingCart] = useAddCartItemToShoppingCartMutation();
  const { sections } = course || {};

  const handleContinue = async (value?: Section): Promise<void> => {
    const cartItemMetadata = value
      ? {
          enrollmentData: {
            teamId: value.id,
          },
        }
      : {};

    const addedCartItem = await addCartItemToShoppingCart({
      externalId: courseData?.sys?.id,
      quantity: 1,
      cartItemMetadata,
      stripeProductId: courseData?.stripeProductId,
      shoppingCartId: shoppingCartData?.id,
      itemType: CartItemTypes.COURSE,
    }).unwrap();

    if (addedCartItem) {
      onComplete();
    }
  };

  const renderSectionCards = (): React.ReactElement<any> | React.ReactElement<any>[] => {
    if (isCourseLoading) {
      return (
        <SwiperSlide key='section-card-skeleton'>
          <SectionCardItemWrapper>
            <Skeleton type='section-card' container={StyledSectionCard} />
          </SectionCardItemWrapper>
        </SwiperSlide>
      );
    }
    return sections?.map((item) => {
      const section = { ...item.attributes, id: item.id };
      return (
        <SwiperSlide key={`section-slide-${item.id}`}>
          <SectionCardItemWrapper>
            <SectionCard item={section} onChange={handleContinue} />
          </SectionCardItemWrapper>
        </SwiperSlide>
      );
    });
  };

  return (
    <Modal.Medium isOpen={isOpen} onClose={onClose} title='Join a Team'>
      <Wrapper>
        <Container>
          <TitleContainer>
            <Title style={{ fontWeight: 300 }}>{courseData?.shortTitle}</Title>
            <Subtitle>
              <TextBold>Choose a Learning Team</TextBold>
              {isCourseLoading ? (
                <Text>
                  <Skeleton type='text' />
                </Text>
              ) : (
                <Text>
                  {sections?.length} {pluralize('team', 'teams', sections?.length)}
                </Text>
              )}
            </Subtitle>
          </TitleContainer>
          <SwiperContainer>
            <NooderaSwiper
              id={`noodera-swiper-${courseData?.slug}`}
              showArrows={width > TABLET_SMALLER_WIDTH && sections?.length > 1}
              spaceBetween={16}
              slidesPerView={
                sections?.length === 1 || isCourseLoading || width <= TABLET_SMALL_WIDTH ? 1 : 2
              }
            >
              {renderSectionCards()}
            </NooderaSwiper>
          </SwiperContainer>
          <ModalFooter>
            Or{' '}
            <LinkButton isActive={false} onClick={() => handleContinue()}>
              Start Studying Solo{' '}
            </LinkButton>
          </ModalFooter>
        </Container>
      </Wrapper>
    </Modal.Medium>
  );
};

export default JoinTeamModal;
